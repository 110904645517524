import { ComponentRef, PageRef } from '@wix/editor-platform-sdk-types';

import {
  addSearchAppController,
  connectControllerWithSearchResults,
} from '../searchAppController';

import {
  addSearchResultsHeader,
  getSearchResultsComponent,
  getSearchResultsPage,
  updateSearchResultsPageData,
} from '../searchResults';

import { addSearchBox } from '../searchBox';
import { EditorAppContext } from '../types';
import { Interaction } from '../constants/interaction';
import { Spec } from '../../lib/specs';
import { SEARCH_RESULTS_PAGE_URL_SLUG } from '../constants';

export async function doFirstInstall(appContext: EditorAppContext) {
  const { fedops, editorSDK, appDefinitionId, experiments } = appContext;
  fedops.interactionStarted(Interaction.InstallApp);

  const [searchResultsRef, searchResultsPageRef] = await Promise.all([
    getSearchResultsComponent(appContext),
    getSearchResultsPage(appContext),
  ]);

  const searchBoxRef = await installAppComponentsConsideringCE(
    appContext,
    searchResultsRef,
    searchResultsPageRef,
  );

  if (experiments.enabled(Spec.NewSearchPageUrlSlug)) {
    await editorSDK.pages.data.update(appDefinitionId, {
      pageRef: searchResultsPageRef,
      data: { pageUriSEO: SEARCH_RESULTS_PAGE_URL_SLUG },
    });
  }

  await editorSDK.pages.navigateTo(appDefinitionId, {
    pageRef: searchResultsPageRef,
  });

  if (searchBoxRef) {
    await editorSDK.selection.selectComponentByCompRef(appDefinitionId, {
      compsToSelect: [searchBoxRef],
    });
  }

  fedops.interactionEnded(Interaction.InstallApp);
}

async function installAppComponents(
  appContext: EditorAppContext,
  searchResultsRef: ComponentRef | undefined,
  searchResultsPageRef: PageRef,
) {
  const searchBox = await addSearchBox(appContext);
  if (searchResultsRef) {
    await addSearchResultsHeader(appContext, {
      searchResultsPageRef,
      searchResultsRef,
    });
  }

  await updateSearchResultsPageData(appContext, {
    searchResultsPageRef,
  });

  const searchAppControllerRef = await addSearchAppController(appContext);

  if (searchResultsRef) {
    await connectControllerWithSearchResults(
      appContext,
      searchAppControllerRef,
      searchResultsRef,
    );
  }

  return searchBox;
}

async function installAppComponentsConsideringCE(
  appContext: EditorAppContext,
  searchResultsRef: ComponentRef | undefined,
  searchResultsPageRef: PageRef,
) {
  const { editorSDK, reportError, appDefinitionId } = appContext;
  try {
    return await editorSDK.document.transactions.runAndWaitForApproval(
      appDefinitionId,
      async () => {
        await installAppComponents(
          appContext,
          searchResultsRef,
          searchResultsPageRef,
        );
      },
    );
  } catch (error) {
    reportError(error);
  }
}
